<template>
  <table-attributes attribute-name="tags" />
</template>

<script>
import TableAttributes from '../components/TableTagsCateBrand.vue'

export default {
  components: {
    tableAttributes: TableAttributes
  }
}
</script>

<style scoped>

</style>
